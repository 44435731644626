<template>
  <div v-if="!isLoading">
    <!-- ORDERS COUNT & SEARCH BOX -->
    <b-row class="mb-1">
      <!-- ORDERS COUNT -->
      <b-col
        cols="12"
        lg="6"
        class="mb-1"
      >
        <b-row>
          <b-col
            cols="6"
            class="pr-0"
          >
            <h5
              class="text-white py-1 px-1 rounded-left"
              style="background-color:#EA5455"
            >
              <b>{{ checkCart }} {{ $t('orders') }}</b>
            </h5>
          </b-col>
          <b-col
            cols="6"
            class="pl-0"
          >
            <h5 class="py-1 px-1 border rounded-right">
              <b>{{ $t('total_of') }} {{ overallQty }} {{ $t('box') }}</b>
            </h5>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- SELECT VEHICLE & DRIVER FIELDS -->
    <b-card>
      <b-row>
        <b-col md="6">
          <h6>{{ $t('select_vehicle_plate_number') }}</h6>
          <b-form-group>
            <v-select
              v-model="form.vehicle"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="vehicles"
              label="plate"
              :placeholder="$t('select_a_vehicle')"
              class="text-uppercase font-weight-bold"
            >
              <template #option="{ image, plate }">
                <div>
                  <img
                    :src="`${$s3URL}uploads/user/${image}`"
                    :alt="plate"
                    width="50"
                  >
                  <span class="text-uppercase font-weight-bold"> {{ plate }}</span>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <h6>{{ $t('select_driver') }}</h6>
          <b-form-group>
            <v-select
              v-model="form.driver"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="riders"
              label="first_name"
              :placeholder="$t('select_a_driver')"
              class="text-uppercase font-weight-bold bg-white"
            >
              <template #option="{ last_name, first_name }">
                <span> {{ first_name }} {{ last_name }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- ALERT MESSAGE FOR EMPTY CART -->
    <b-row>
      <b-col cols="12">
        <b-alert
          :show="!cart"
          variant="primary"
        >
          <div class="alert-body mb-4">
            <span><strong>{{ $t('there_are_no_items_in_cart') }}</strong></span>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <!-- ORDERS TABLE -->
    <b-row
      :show="cart"
      class="mb-2"
    >
      <b-col cols="12">
        <MyTable
          :rows="cart"
          :columns="columns"
          :is-loading="isLoading"
          :title="$t('items')"
        >
          <template v-slot="{props}">
            <span v-if="props.column.field === 'id'">
              <span
                class="text-primary font-weight-bold"
              >{{ props.row.reference_id }}</span>
            </span>

            <span v-else-if="props.column.field === 'po_number'">
              {{ props.row.po_number }}
            </span>

            <span v-else-if="props.column.field === 'po_expiry_date'">
              {{ props.row.po_expiry_date }}
            </span>

            <span v-else-if="props.column.field === 'sender_address'">
              {{ props.row.sender.complete }}
            </span>

            <span v-else-if="props.column.field === 'recipient_address'">
              {{ props.row.recipient.complete }}
            </span>

            <span v-else-if="props.column.field === 'smarttruck_cut'">
              {{ $helpers.formatTotal(+props.row.smarttruck_cut + +props.row.pickup_charge) }}
            </span>

            <span v-else-if="props.column.field === 'actions'">
              <b-button
                variant="primary"
                @click="removeItemCart(props.row.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </MyTable>
      </b-col>
    </b-row>

    <!-- TOTAL EARNINGS -->
    <b-row class="mb-2">
      <b-col
        cols="8"
        lg="4"
        class="ml-auto"
      >
        <b-row>
          <b-col
            cols="5"
            class="pr-0"
          >
            <h5
              class="text-white py-1 px-1 rounded-left"
              style="background-color:#EA5455"
            >
              <b>{{ $t('total_earn') }}</b>
            </h5>
          </b-col>
          <b-col
            cols="7"
            class="pl-0"
          >
            <h5 class="py-1 px-1 border rounded-right">
              <b>{{ $helpers.formatTotal(netEarnings) }}</b>
            </h5>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- GO HYPERMARKET BUTTON -->
    <b-row class="mb-2">
      <b-col cols="12 text-center">
        <b-button
          variant="danger"
          :to="{name:'hypermarket-order'}"
          class="mr-1"
        >{{ $t('go_hypermarket_order') }}</b-button>
        <b-button
          v-if="checkCart > 0"
          variant="danger"
          @click="confirm()"
        >{{ $t('confirm_delivery') }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@axios'
import { mapGetters, mapActions } from 'vuex';
import MyTable from '@/components/MyTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    MyTable,
  },
  data() {
    return {
      show: false,
      filter: null,
      filterOn: [],
      form: {
        driver: null,
        vehicle: null,
      },
      driver: null,
      columns: [
        {
          field: 'id',
          type: 'number',
          label: this.$t('order_id'),
          tdClass: 'align-middle text-left pl-2',
        },
        {
          field: 'po_number',
          type: 'number',
          label: this.$t('PO_number'),
          tdClass: 'text-nowrap align-middle text-left pl-1',
          thClass: 'text-left',
        },
        {
          field: 'po_expiry_date',
          dateInputFormat: 'dd-MM-yyyy',
          label: this.$t('PO_expiry_date'),
          tdClass: 'text-nowrap align-middle',
        },
        {
          field: 'sender_address',
          sortable: false,
          label: this.$t('sender_address'),
          tdClass: 'align-middle',
        },
        {
          field: 'recipient_address',
          sortable: false,
          label: this.$t('recipient_address'),
          tdClass: 'align-middle',
        },
        {
          field: 'smarttruck_cut',
          sortable: false,
          label: this.$t('total'),
          tdClass: 'text-nowrap align-middle',
        },
        {
          field: 'actions',
          sortable: false,
          label: this.$t('action'),
          tdClass: 'text-nowrap align-middle',
        },
      ],
      vehicles: [],
      riders: [],
    }
  },
  computed: {
    ...mapGetters({
      cart: 'getCartItems',
      checkCart: 'getCartItemsLength',
      isLoading: 'orders/checkLoadingState',
    }),
    netEarnings() {
      return (Math.round(this.grossEarnings * 100) / 100)
    },
    grossEarnings() {
      const cart = this.$store.getters.getCartItems
      const total = cart.map(item => +item.smarttruck_cut + +item.pickup_charge)
      return total.reduce((prev, curr) => prev + curr, 0)
    },
    totalPickupCharge() {
      const cart = this.$store.getters.getCartItems
      const total = cart.map(item => +item.pickupCharge)
      return total
    },
    overallQty() {
      const cart = this.$store.getters.getCartItems
      const quantity = cart.map(item => +item.quantity)
      return quantity.reduce((prev, curr) => prev + curr, 0)
    },
  },
  created() {
    this.getDrivers()
    this.getVehicles()
    this.fetchCartItems();
  },
  methods: {
    ...mapActions(['fetchCartItems']),
    async confirm() {
      const self = this
      if (!self.form.vehicle) {
        this.$swal({
          title: this.$t('assign_a_vehicle'),
          text: this.$t('you_need_to_select_a_vehicle'),
          icon: 'warning',
          confirmButtonText: 'OK',
          timer: 4000,
          timerProgressBar: true,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      if (!self.form.driver) {
        this.$swal({
          title: this.$t('please_select_a_driver'),
          text: this.$t('you_need_to_assign_a_driver'),
          icon: 'warning',
          confirmButtonText: 'OK',
          timer: 4000,
          timerProgressBar: true,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      const cart = this.$store.getters.getCartItems
      const orders = []
      cart.map(item => orders.push(item.id))

      self.show = true
      const response = await axios.post('accept-orders', {
        order_ids: orders,
        driver_id: self.form.driver.id,
        vehicle_id: self.form.vehicle.id,
      })

      if (response.status === 422) {
        this.$swal({
          title: 'Error!',
          text: 'Something went wrong. Please try again.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          allowOutsideClick: false,
          buttonsStyling: false,
        })
        return
      }

      self.show = false
      self.$store.dispatch('clearCart')
      localStorage.setItem('deliveryId', response.data.id)
      await this.$swal({
        title: 'Success!',
        text: 'You have successfully assigned the items for delivery! Please check the deliveries page.',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        allowOutsideClick: false,
        buttonsStyling: false,
      })
      self.$router.push({ name: 'deliveryconfirmation' })
    },
    removeItemCart(id) {
      this.$swal({
        title: this.$t('are_you_sure'),
        text: this.$t('remove_item_from_cart'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yes_remove_it'),
        cancelButtonText: this.$t('cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('removeCartItem', id)
          this.$swal({
            icon: 'success',
            title: this.$t('removed'),
            text: this.$t('item_removed'),
            confirmButtonText: this.$t('ok'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    getDrivers() {
      const self = this
      const headers = {
        Accept: 'application/json',
      }
      axios.get(`${this.$appURL}getRiders`, { headers })
        .catch(() => {})
        .then(response => {
          self.riders = response.data.data
        })
    },
    getVehicles() {
      const self = this
      this.$http.get('getVehicles').then(response => {
        self.vehicles = response.data.custom_data
      })
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
