var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"6"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"cols":"6"}},[_c('h5',{staticClass:"text-white py-1 px-1 rounded-left",staticStyle:{"background-color":"#EA5455"}},[_c('b',[_vm._v(_vm._s(_vm.checkCart)+" "+_vm._s(_vm.$t('orders')))])])]),_c('b-col',{staticClass:"pl-0",attrs:{"cols":"6"}},[_c('h5',{staticClass:"py-1 px-1 border rounded-right"},[_c('b',[_vm._v(_vm._s(_vm.$t('total_of'))+" "+_vm._s(_vm.overallQty)+" "+_vm._s(_vm.$t('box')))])])])],1)],1)],1),_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('h6',[_vm._v(_vm._s(_vm.$t('select_vehicle_plate_number')))]),_c('b-form-group',[_c('v-select',{staticClass:"text-uppercase font-weight-bold",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.vehicles,"label":"plate","placeholder":_vm.$t('select_a_vehicle')},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var image = ref.image;
var plate = ref.plate;
return [_c('div',[_c('img',{attrs:{"src":(_vm.$s3URL + "uploads/user/" + image),"alt":plate,"width":"50"}}),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(plate))])])]}}],null,false,326351355),model:{value:(_vm.form.vehicle),callback:function ($$v) {_vm.$set(_vm.form, "vehicle", $$v)},expression:"form.vehicle"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('h6',[_vm._v(_vm._s(_vm.$t('select_driver')))]),_c('b-form-group',[_c('v-select',{staticClass:"text-uppercase font-weight-bold bg-white",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.riders,"label":"first_name","placeholder":_vm.$t('select_a_driver')},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var last_name = ref.last_name;
var first_name = ref.first_name;
return [_c('span',[_vm._v(" "+_vm._s(first_name)+" "+_vm._s(last_name))])]}}],null,false,3617704616),model:{value:(_vm.form.driver),callback:function ($$v) {_vm.$set(_vm.form, "driver", $$v)},expression:"form.driver"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"show":!_vm.cart,"variant":"primary"}},[_c('div',{staticClass:"alert-body mb-4"},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$t('there_are_no_items_in_cart')))])])])])],1)],1),_c('b-row',{staticClass:"mb-2",attrs:{"show":_vm.cart}},[_c('b-col',{attrs:{"cols":"12"}},[_c('MyTable',{attrs:{"rows":_vm.cart,"columns":_vm.columns,"is-loading":_vm.isLoading,"title":_vm.$t('items')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var props = ref.props;
return [(props.column.field === 'id')?_c('span',[_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v(_vm._s(props.row.reference_id))])]):(props.column.field === 'po_number')?_c('span',[_vm._v(" "+_vm._s(props.row.po_number)+" ")]):(props.column.field === 'po_expiry_date')?_c('span',[_vm._v(" "+_vm._s(props.row.po_expiry_date)+" ")]):(props.column.field === 'sender_address')?_c('span',[_vm._v(" "+_vm._s(props.row.sender.complete)+" ")]):(props.column.field === 'recipient_address')?_c('span',[_vm._v(" "+_vm._s(props.row.recipient.complete)+" ")]):(props.column.field === 'smarttruck_cut')?_c('span',[_vm._v(" "+_vm._s(_vm.$helpers.formatTotal(+props.row.smarttruck_cut + +props.row.pickup_charge))+" ")]):(props.column.field === 'actions')?_c('span',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.removeItemCart(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,1012499317)})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"ml-auto",attrs:{"cols":"8","lg":"4"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"cols":"5"}},[_c('h5',{staticClass:"text-white py-1 px-1 rounded-left",staticStyle:{"background-color":"#EA5455"}},[_c('b',[_vm._v(_vm._s(_vm.$t('total_earn')))])])]),_c('b-col',{staticClass:"pl-0",attrs:{"cols":"7"}},[_c('h5',{staticClass:"py-1 px-1 border rounded-right"},[_c('b',[_vm._v(_vm._s(_vm.$helpers.formatTotal(_vm.netEarnings)))])])])],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12 text-center"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger","to":{name:'hypermarket-order'}}},[_vm._v(_vm._s(_vm.$t('go_hypermarket_order')))]),(_vm.checkCart > 0)?_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t('confirm_delivery')))]):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }